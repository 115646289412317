body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar */
}

/* The track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
  border-radius: 10px; /* Rounded corners */
}

/* The scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #888; /* Darker gray color */
  border-radius: 10px; /* Rounded corners */
}

/* The scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker on hover */
}
